import {
  ActionTree,
  GetterTree,
  MutationTree,
  Module as VuexModule
} from 'vuex';
import config from '@/common/config';
import { PromiseResponse } from '@/common/global.interfaces';
import type { RootState } from '@/common/store';
import { Http } from '@samknows/utils';

export enum ApiType {
  DATA_API = 'data_api',
  METADATA_API = 'metadata_api',
  INSTANT_TEST_API = 'instant_test_api',
  TEST_SCHEDULE_AND_MOVE_API = 'shared_api'
}

export interface Token {
  id: number;
  name: string;
  apiName: ApiType;
  isLegacyToken: boolean;
  createdAt: string | null;
  updatedAt: string | null;
}

export interface ApiTokenList {
  apiType: ApiType;
  tokens: Token[];
}

export type ApiTokens = {
  [api in ApiType]?: Token[];
};

export interface ApisViewState {
  apiTokens: ApiTokenList[];
  tokenIdToRegenerate: number | null;
}

export interface PlainApiToken {
  plainToken: string;
  token: Token;
}

const state = (): ApisViewState => ({
  apiTokens: [],
  tokenIdToRegenerate: null
});

const getters: GetterTree<ApisViewState, RootState> = {
  apiTokens: (state): ApiTokenList[] => {
    return state.apiTokens;
  },
  isRegeneratingToken: (state): boolean => {
    return state.tokenIdToRegenerate !== null;
  },
  regeneratingTokenName: (state) => {
    for (const api of state.apiTokens) {
      for (const token of api.tokens) {
        if (token.id === state.tokenIdToRegenerate) {
          return token.name;
        }
      }
    }
    return '';
  }
};

const mutations: MutationTree<ApisViewState> = {
  setApiTokens: (state, tokens: ApiTokenList[]) => {
    state.apiTokens = tokens;
  },
  replaceApiToken: (
    state: ApisViewState,
    tokenData: { oldTokenId: number; newToken: Token }
  ) => {
    state.apiTokens.forEach((api: ApiTokenList) => {
      const oldTokenIndex = api.tokens.findIndex(
        (token) => token.id === tokenData.oldTokenId
      );
      if (oldTokenIndex >= 0) {
        api.tokens[oldTokenIndex] = tokenData.newToken;
      }
    });
  },
  setTokenIdToRegenerate: (state, tokenId: number | null) => {
    state.tokenIdToRegenerate = tokenId;
  }
};

const actions: ActionTree<ApisViewState, RootState> = {
  async loadAllTokens({ commit, rootState }): Promise<void> {
    const panelId = rootState.panel.pid;
    try {
      const loadApiTokens: PromiseResponse<ApiTokens> = await Http.request(
        `${config.api.zeus}/panels/${panelId}/api_tokens`
      );

      const apiKeys = Object.keys(loadApiTokens?.data) as ApiType[];

      const apiTokensList: ApiTokenList[] = [];
      apiKeys.forEach((api) => {
        apiTokensList.push({
          apiType: api,
          tokens: loadApiTokens?.data[api]
        });
      });

      commit('setApiTokens', apiTokensList);
    } catch (error) {
      // TODO SKWEB-343 handle gracefully
      throw error;
    }
  },

  showRegenerateTokenModal({ commit }, tokenId): void {
    commit('setTokenIdToRegenerate', tokenId);
  },

  hideRegenerateTokenModal({ commit }): void {
    commit('setTokenIdToRegenerate', null);
  },

  async regenerateToken({
    rootState,
    state,
    commit,
    dispatch
  }): Promise<string> {
    const panelId = rootState.panel.pid;
    const tokenId = state.tokenIdToRegenerate;
    try {
      const tokenResponse = await Http.request<PromiseResponse<PlainApiToken>>(
        `${config.api.zeus}/panels/${panelId}/api_token/${tokenId}/regenerate`,
        { method: 'POST' }
      );

      const newToken = tokenResponse.data.token;
      commit('replaceApiToken', { oldTokenId: tokenId, newToken });
      dispatch('loadAllTokens'); // not awaited, should happen in the background

      return tokenResponse.data.plainToken;
    } catch (error) {
      // TODO SKWEB-343 handle gracefully
      throw error;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as VuexModule<ApisViewState, RootState>;
